import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const WorkPage = () => (
  <Layout>
    <SEO title="Work" />
    <section id="work">
      <h1>The Work.</h1>
      <p>
       I enjoy making performant websites for members of my community, local and abroad. 
      </p>
      
      <ul>
        <li> 
          <article>
            <h2>
              <a href="https://ambeli-menu.onrender.com" target="_blank" rel="noopener noreferrer">
                Ambeli Greek Taverna Digital Menu
              </a>
            </h2>
            <h3>Cranford, NJ&apos;s <a href='https://www.ambeligreek.com'>Ambeli Taverna</a> has a new online menu.</h3>
            <p>
              One of my first dates with my wife was at this local, family-owned Greek restaurant. I was proud to help them five years later, when the COVID-19 pandemic greatly affected restaurants around the world.
            </p>
            <p>This digital menu, accessed via a QR code at your table, highlights new photography as well as lists dietary restrictions throughout their always-fresh menu.</p>
          </article>
        </li>
     
        <li key='2'>
          <article>
            <h2>
              <a href="https://www.martinfamilystrong.org" target="_blank" rel="noopener noreferrer">
                Martin Family Strong
              </a>
            </h2>
            <h3>The official website for the Martin Family.</h3>
            <p>
              In 2019, the Martin Family convened for its first reunion in
              twenty years. Martins from all over the country met to form new
              bonds and refresh old ones that summer, and interest quickly
              evolved in repeating the event the following summer.
            </p>
            <p>
              The summer of 2020 was full of unforeseen events that prevented
              this. However there was still a need for family members to keep in
              touch and stay up to date on family news. With the free time  at home 2020 afforded us all, I decided to rebuild the family site.</p>
              <p>Formerly a brochure for the 2019 reunion, the sit enow includes news, a blog, a gallery of family photos, and contact form to reach out to the family. Now if only my father would submit regular updates, ...
            </p>
          </article>
        </li>
      </ul>
   
    </section>
  </Layout>
)

export default WorkPage
